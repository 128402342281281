<template>
  <div class="question-list tip_question-list">
    <el-breadcrumb separator="> " class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/questionTemplate' }"> 模板管理 </el-breadcrumb-item>
      <el-breadcrumb-item>{{ $route.query.id ? '编辑' : '创建' }}模板</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="group_title">模板设置</div>
    <el-form ref="form" :model="templateData" :rules="formRules" label-width="80px">
      <el-form-item required label="模板名称" prop="templateName">
        <el-input
          v-model.trim="templateData.templateName"
          maxlength="10"
          placeholder="请输入模板名称"
          show-word-limit
        />
      </el-form-item>

      <!-- 内容设置 -->
      <div class="group_title">内容设置</div>
      <el-steps :active="3" style="width: 500px; margin: 0 auto;">
        <el-step title="设置分类" />
        <el-step title="添加问题" />
        <el-step title="提交配置" />
      </el-steps>

      <div class="add_tab">
        <el-button
          :disabled="classificaReqDTOS.length >= 10"
          @click="addOrEditTab()"
          size="mini"
          type="primary"
        >
          添加分类
        </el-button>
      </div>

      <div key="分类tab" v-if="classificaReqDTOS.length" class="tabs">
        <draggable v-model="classificaReqDTOS" @end="tabOnSortEnd" class="tabs">
          <div
            v-for="(item, index) in classificaReqDTOS"
            :key="index"
            :active="activeTabIdx === index"
            @click="activeTabIdx = index"
            class="tab_item"
          >
            <div class="tab_title">
              <span>{{ item.classificaName }}</span>
              <i @click.stop="addOrEditTab(index)" class="el-icon-edit tab_edit_btn" />
              <i @click.stop="delTap(index)" class="el-icon-close" />
            </div>
          </div>
        </draggable>
      </div>
      <div v-else class="empty_data">暂无内容</div>

      <div key="问题列表" v-if="classificaReqDTOS[activeTabIdx]">
        <table class="table">
          <thead>
            <th>问题编码</th>
            <th>问题名称</th>
            <th>答复</th>
            <th>操作</th>
          </thead>
          <draggable v-model="classificaReqDTOS[activeTabIdx].problemList" tag="tbody">
            <tr v-for="(item, index) in classificaReqDTOS[activeTabIdx].problemList" :key="item.id">
              <td>{{ item.titleCode }}</td>
              <td>
                <el-tooltip :content="item.title" class="item" effect="dark" placement="top">
                  <div class="question_wrap">
                    {{ item.title }}
                  </div>
                </el-tooltip>
              </td>
              <td>
                <el-tooltip :content="item.reply" class="item" effect="dark" placement="top">
                  <div v-html="item.reply" class="question_wrap" />
                  <span slot="content" v-html="item.reply" class="tip_wrap" />
                </el-tooltip>
              </td>
              <td class="btn_wrap">
                <el-button
                  v-if="checkPermission('KFXT_ZSKMB_ZSKMBSC')"
                  @click="delQuestion(item, index)"
                  type="text"
                  size="small"
                >
                  删除
                </el-button>
                <span v-if="checkPermission('KFXT_ZSKMB_ZSKMBZD')" @click="topHandle(item, index)">
                  {{ item.isTop ? '取消置顶' : '置顶' }}
                </span>
              </td>
            </tr>
          </draggable>
        </table>

        <div
          v-if="
            (classificaReqDTOS[activeTabIdx] && !classificaReqDTOS[activeTabIdx].problemList) ||
            (classificaReqDTOS[activeTabIdx] &&
              classificaReqDTOS[activeTabIdx].problemList &&
              classificaReqDTOS[activeTabIdx].problemList.length === 0)
          "
          class="empty_data"
        >
          暂无内容
        </div>

        <el-button @click="addQuestion" plain class="add-question-btn"> + 添加问题 </el-button>
      </div>

      <!-- 其他设置 -->
      <div class="group_title mt22">其他设置</div>

      <el-form-item label="自助页banner" label-width="120px">
        <div class="edit_wrap">
          <el-upload
            :headers="{ token }"
            :show-file-list="false"
            :on-success="successHandle"
            :before-upload="beforeCoverUpload"
            :action="uploadUrl"
            name="files"
            class="ndd-uploader"
            accept=".jpg,.jpeg,.png"
            list-type="picture-card"
          >
            <div v-if="fileUrl" class="ndd-uploader-img">
              <img :src="fileUrl" alt="" />
              <span @click.stop="removeHandle" class="ndd-uploader-delete">删除</span>
            </div>
            <i v-else class="el-icon-plus ndd-uploader-icon"></i>
          </el-upload>

          <div slot="tip" class="el-upload__tip">建议尺寸：1125x720，不超过500KB,JPG\PNG</div>
        </div>
      </el-form-item>

      <el-form-item label="其他问题内容" label-width="120px" prop="otherProblems">
        <el-row>
          <el-col :span="10">
            <el-input
              v-model.trim="templateData.otherProblems"
              maxlength="30"
              show-word-limit
              placeholder="请输入"
            />
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 按钮组配置 -->
      <div v-for="(btnConf, idx) in btnConfigs" :key="idx" class="btn-groups">
        <el-row :gutter="20">
          <el-form-item :label="`按钮${indexName(idx)}名称`" label-width="120px">
            <el-col :span="8">
              <el-input
                v-model.trim="btnConf.buttonName"
                @blur="e => checkIsRepeat(btnConf, idx, e)"
                maxlength="10"
                placeholder="请输入"
              />
            </el-col>

            <el-col :span="12">
              <el-button
                v-if="idx === 0"
                :disabled="btnConfigs.length > 5"
                @click="addBtnConf"
                type="text"
              >
                添加
              </el-button>
              <el-button v-else-if="idx !== 0" @click="deleteBtnConf(btnConf, idx)" type="text">
                删除
              </el-button>
            </el-col>
          </el-form-item>

          <el-col :span="8">
            <el-form-item :label="`按钮${indexName(idx)}点击效果`" label-width="120px">
              <el-radio-group v-model="btnConf.clickEffect">
                <el-radio v-for="(m, n) in btnTypeOptions" :key="n" :label="m.value">
                  {{ m.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :offset="1" :span="15">
            <el-form-item v-if="btnConf.clickEffect === '1'" label="弹窗文案" label-width="120px">
              <el-input
                v-model="btnConf.popUpText"
                :autosize="{ minRows: 2, maxRows: 5 }"
                @blur="e => inputBlur(e, btnConf, idx, 'popUpText')"
                show-word-limit
                type="textarea"
                maxlength="200"
                placeholder="请输入"
              />
            </el-form-item>

            <!-- @blur="e => inputBlur(e, btnConf, idx, 'jumpLink')" -->
            <el-form-item v-else-if="btnConf.clickEffect === '2'" label="链接" label-width="120px">
              <el-input
                v-model.trim="btnConf.jumpLink"
                :autosize="{ minRows: 2, maxRows: 5 }"
                show-word-limit
                type="textarea"
                maxlength="200"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>

    <!-- 提交 -->
    <div class="footer">
      <el-button @click="save" type="primary"> 提交 </el-button>
    </div>

    <!-- 问题弹窗 -->
    <el-dialog
      :visible.sync="showQuestionDialog"
      :close-on-click-modal="false"
      @close="showQuestionDialog = false"
      top="15px"
      title="提示"
      width="80%"
      lock-scroll
      append-to-body
      destroy-on-close
    >
      <div class="question-body">
        <questionList
          ref="questionsList"
          v-if="showQuestionDialog"
          :ids="ids"
          :status="0"
          :autoHeight="70"
          :is-component="true"
        />
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showQuestionDialog = false">取 消</el-button>
        <el-button @click="saveQuestion" type="primary">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加分类弹窗 -->
    <el-dialog
      :visible.sync="tabModal.show"
      :close-on-click-modal="false"
      :title="typeof tabModal.index === 'number' ? '编辑分类' : '添加分类'"
      @close="tabDialogOnClose"
      center
      width="30%"
      lock-scroll
      append-to-body
      destroy-on-close
    >
      <el-form
        ref="tabForm"
        :model="tabForm"
        :rules="tabFormRules"
        :inline="false"
        label-width="120px"
        size="normal"
      >
        <el-form-item label="分类编码" size="normal" prop="classificaId" hidden />

        <el-form-item label="分类名称" required prop="classificaName">
          <el-input
            v-model.trim="tabForm.classificaName"
            maxlength="20"
            placeholder="请输入分类名称"
          />
        </el-form-item>

        <el-form-item label="是否标记" size="normal" required prop="isFlag">
          <el-radio-group v-model="tabForm.isFlag">
            <el-radio v-for="(item, idx) in tfOptions" :key="idx" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="C端是否展示" size="normal" required prop="showForCFlag">
          <el-radio-group v-model="tabForm.showForCFlag">
            <el-radio v-for="(item, idx) in tfOptions" :key="idx" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click.stop="tabDialogOnClose">取消</el-button>
        <el-button @click.stop="tabDialogOnOnSubmit()" type="primary">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import draggable from 'vuedraggable';
import Config from '@/config';
import { question } from '@/api';
import { getToken, isEmptyObj } from '@/utils/util';
import questionList from '../question/list';

export default {
  components: {
    questionList,
    draggable,
  },
  data() {
    return {
      token: getToken(),
      tfOptions: [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ],

      activeTabIdx: 0, // 分类激活下标

      // 分类弹窗
      tabModal: {
        index: null,
        show: false,
      },
      // 分类form
      tabForm: {
        classificaId: '', // 分类编码
        classificaName: '', // 分类名称
        isFlag: 0, // 是否标记
        showForCFlag: 1, // 是否标记
        problemList: [], // 内容设置详细信息
      },
      // 分类form-rules
      tabFormRules: {
        classificaName: [{ required: true, message: '分类名称不能为空' }],
        isFlag: [{ required: true, message: '是否标记不能为空' }],
        showForCFlag: [{ required: true, message: 'C端是否展示不能为空' }],
      },

      // 分类模块数组
      classificaReqDTOS: [],
      ids: [],

      // 自助页banner
      fileUrl: '', // 图片地址
      uploadUrl: `${Config.baseUrl}/api/im-web/file/upload`,

      // 按钮组配置
      btnTypeOptions: [
        { label: '无效果', value: '0' },
        { label: '弹窗', value: '1' },
        { label: '跳转链接', value: '2' },
      ],
      btnConfigs: [
        {
          buttonName: '',
          clickEffect: '',
          popUpText: '',
          jumpLink: '',
        },
      ],

      showQuestionDialog: false, // 是否显示问题弹窗
      templateData: {
        id: '', // id
        isUse: 0,
        delete: 0,
        isDelete: 0,
        gmtCreate: '',
        gmtModified: '',
        templateId: '', // 模版ID
        templateName: '', // 模版名称
        telCustomerHotline: '', // 电话客服热线
        showSmartCustomerEntry: 0, // 是否展示智能客服入口
        smartCustomerUrl: '', // 智能客服链接
        otherProblems: '', // 其他问题内容
      },
      formRules: {
        templateName: [{ required: true, message: '请输入模板名称' }],
        telCustomerHotline: [
          { required: true, message: '请输入电话客服热线' },
          {
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入电话客服热线'));
              } else if (!/[0-9\u4e00-\u9fa5-①②③④⑤⑥⑦⑧⑨⑩]$/i.test(value)) {
                callback(new Error('仅支持输入数字、中文、-、① ~ ⑩'));
              } else {
                callback();
              }
            },
          },
        ],
        showSmartCustomerEntry: [{ required: true, message: '请选择是否展示智能客服入口' }],
      },
    };
  },
  computed: {},
  watch: {
    'templateData.showSmartCustomerEntry': {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue === 1) {
          this.formRules.smartCustomerUrl = [{ required: true, message: '请输入智能客服链接' }];
        } else {
          this.formRules.smartCustomerUrl = [];
        }
      },
    },
  },
  created() {
    const { id } = this.$route.query;
    if (id) {
      question.getTemplateDetail({ id }).then(res => {
        const data = res.data || {};
        this.templateData = {
          ...data,
          id,
          templateId: data.templateId,
          templateName: data.templateName,
          telCustomerHotline: data.telCustomerHotline,
          showSmartCustomerEntry: data.showSmartCustomerEntry || 0,
          smartCustomerUrl: data.smartCustomerUrl,
        };

        // 分类&问题
        const arr = [];
        (data.classificaReqDTOS || []).forEach(d => {
          const dt = { ...d };
          const problemList = [];

          // eslint-disable-next-line no-unused-expressions
          d.problemList &&
            d.problemList.forEach(f => {
              const ft = { ...f };
              ft.isTop = false;
              problemList.push(ft);
            });

          dt.problemList = problemList;
          arr.push(dt);
        });
        this.classificaReqDTOS = arr;

        // 其他设置
        this.fileUrl = data?.bannerUrl ?? '';

        this.btnConfigs =
          !data?.buttonCfgDTOS ||
          (Array.isArray(data?.buttonCfgDTOS) && data.buttonCfgDTOS?.length === 0)
            ? [{ buttonName: '', clickEffect: '', popUpText: '', jumpLink: '' }]
            : data?.buttonCfgDTOS;
      });
    }
  },
  methods: {
    ...mapActions('question', ['imagesUploader']),

    /** @function 获取下标对应中文name */
    indexName(idx) {
      return ['一', '二', '三', '四', '五', '六'][idx];
    },

    /** @function 弹窗文案input失焦处理 */
    inputBlur(e, item, idx, key) {
      console.log('弹窗文案input编辑 => ', e, item, idx, key);
      const newItem = _.cloneDeep(item);
      const val = e.target.value?.trim?.();
      // eslint-disable-next-line no-param-reassign
      newItem[key] = val;
      const newBtnConfigs = _.cloneDeep(this.btnConfigs);
      newBtnConfigs.splice(idx, 1, newItem);
      this.$set(this, 'btnConfigs', newBtnConfigs);
    },

    /** @function 检查权限 */
    checkPermission(code) {
      return this.$store.state.permissionData.indexOf(code) !== -1;
    },

    /** @function 添加|编辑分类 */
    addOrEditTab(index) {
      console.log(index, this.classificaReqDTOS[index], '>>>>> 添加|编辑分类');
      const arr = _.cloneDeep(this.classificaReqDTOS);

      if (typeof index === 'number') {
        this.tabForm = {
          ...arr[index],
          isFlag: arr[index].isFlag ?? 0,
        };
        this.activeTabIdx = index;
      } else {
        this.tabForm = { isFlag: 0, showForCFlag: 1 };
      }

      this.tabModal = { show: true, index: index ?? null };
    },

    /** @function 添加分类弹窗-关闭事件 */
    tabDialogOnClose() {
      this.tabForm = {};
      this.tabModal = {};
    },

    /** @function 分类弹窗-提交事件 */
    tabDialogOnOnSubmit() {
      this.$refs.tabForm.validate(valid => {
        if (valid) {
          const idx = this.tabModal.index;
          const arr = _.cloneDeep(this.classificaReqDTOS);

          if (typeof idx === 'number') {
            arr.splice(idx, 1, this.tabForm);
          } else {
            arr.push(this.tabForm);
          }

          this.classificaReqDTOS = arr;
          this.activeTabIdx = arr.length - 1;
          this.tabModal = {};
        }
      });
    },

    /** @function 删除分类 */
    delTap(index) {
      const { problemList } = this.classificaReqDTOS[index] || {};
      if (problemList && problemList.length) {
        this.$message.warning('不能删除该分类，因为分类下还有问题');
        return;
      }
      if (index === this.activeTabIdx) {
        this.activeTabIdx = index > 0 ? index - 1 : 0;
      } else if (index < this.activeTabIdx) {
        this.activeTabIdx -= 1;
      }
      this.classificaReqDTOS.splice(index, 1);
    },

    /** @function 分类拖拽 */
    tabOnSortEnd({ newIndex }) {
      this.activeTabIdx = newIndex;
    },

    /** @function 添加问题  */
    addQuestion() {
      if (
        this.classificaReqDTOS[this.activeTabIdx] &&
        this.classificaReqDTOS[this.activeTabIdx].problemList
      ) {
        this.ids = this.classificaReqDTOS[this.activeTabIdx].problemList.map(
          item => item.problemId || item.id,
        );
      } else {
        this.classificaReqDTOS[this.activeTabIdx].problemList = [];
      }

      this.showQuestionDialog = true;
    },

    /** @function 删除问题 */
    delQuestion(item, index) {
      this.$confirm('是否删除该问题?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // this.classificaReqDTOS[this.activeTabIdx].problemList.splice(index, 1);
          const arr = _.cloneDeep(this.classificaReqDTOS);
          const classificaItem = arr[this.activeTabIdx] || {};
          const problemList = classificaItem.problemList || [];
          problemList.splice(index, 1);
          classificaItem.problemList = problemList;
          arr.splice(this.activeTabIdx, 1, classificaItem);
          this.classificaReqDTOS = arr;
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
        })
        .catch();
    },

    /** @function 保存问题 */
    saveQuestion() {
      const { questionsList } = this.$refs;
      questionsList.getData(data => {
        const { problemList } = this.classificaReqDTOS[this.activeTabIdx] || {};
        if (problemList.length + data.length >= 500) {
          this.$message.warning('一个分类下最多添加500个问题');
          return;
        }
        this.classificaReqDTOS[this.activeTabIdx].problemList.push(...data);

        this.showQuestionDialog = false;
      });
    },

    /** @function 校验按钮组信息是否符合要求 */
    checkBtnConfigs() {
      let checkResult = true;
      // 检查其他问题内容
      if (
        this.templateData.otherProblems &&
        this.btnConfigs.filter(it => !!it.buttonName).length === 0
      ) {
        this.$message.error(`至少填写一个按钮内容`);
        return false;
      }

      // eslint-disable-next-line consistent-return, array-callback-return
      this.btnConfigs.some((item, index) => {
        if (item.buttonName && isEmptyObj(item.clickEffect)) {
          this.$message.error(`按钮${this.indexName(index)} - 按钮点击效果不能为空`);
          checkResult = false;
          return true;
        }
        if (item.buttonName && item.clickEffect === '1' && isEmptyObj(item.popUpText)) {
          this.$message.error(`按钮${this.indexName(index)} - 弹窗文案不能为空`);
          checkResult = false;
          return true;
        }
        if (item.buttonName && item.clickEffect === '2' && isEmptyObj(item.jumpLink)) {
          this.$message.error(`按钮${this.indexName(index)} - 链接不能为空`);
          checkResult = false;
          return true;
        }
        if (isEmptyObj(item.buttonName) && !isEmptyObj(item.clickEffect)) {
          this.$message.error(`请填写按钮${this.indexName(index)}名称`);
          checkResult = false;
          return true;
        }
      });

      return checkResult;
    },

    /** @function 提交数据 */
    save: _.debounce(
      function save() {
        this.$refs.form.validate(valid => {
          if (valid) {
            if (!this.classificaReqDTOS.length) {
              this.$message.error('请至少添加一个分类');
              return;
            }

            // 判断按钮组数据
            if (!this.checkBtnConfigs()) {
              return;
            }

            const loading = this.$loading({
              lock: true,
              text: '数据提交中...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
            });

            question
              .saveTemplateMess({
                ...this.templateData,
                classificaReqDTOS: this.classificaReqDTOS,
                bannerUrl: this.fileUrl,
                buttonCfgDTOS: this.btnConfigs?.filter(it => !isEmptyObj(it.buttonName)),
              })
              .then(() => {
                loading.close();
                this.$message.success('保存成功');
                this.$router.push({ path: '/questionTemplate' });
              })
              .catch(e => {
                console.error('e=> ', e);
                loading.close();
              });
          }
        });
      },
      1000,
      {
        leading: true,
      },
    ),

    /** @function 置顶|取消置顶 */
    topHandle(item, index) {
      // console.log('置顶|取消置顶>>>> ', item, index);
      const it = _.cloneDeep(item);
      const arr = _.cloneDeep(this.classificaReqDTOS);
      const classificaItem = arr[this.activeTabIdx] || {};
      const problemList = classificaItem.problemList || [];
      it.isTop = !item.isTop;
      problemList.splice(index, 1, it);
      classificaItem.problemList = problemList;
      arr.splice(this.activeTabIdx, 1, classificaItem);
      this.classificaReqDTOS = arr;
    },

    beforeCoverUpload(file) {
      const isLt2M = file.size / 1024 <= 500;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过500KB!');
      }
      return isLt2M;
    },

    successHandle(res, file, fileList) {
      console.log('uploadOnSuccess>>>> ', res, file, fileList);
      if (res?.code === '0' && res?.data) {
        this.fileUrl = res.data;
      } else {
        this.fileUrl = '';
      }
    },

    /**
     * @function 删除图片
     */
    removeHandle(res) {
      console.log('删除图片 => ', res);
      this.fileUrl = '';
    },

    /** @function 添加按钮组 */
    addBtnConf() {
      console.log('添加按钮组');
      if (this.btnConfigs.length > 5) {
        return;
      }

      this.btnConfigs.push({ buttonName: '', clickEffect: '', popUpText: '', jumpLink: '' });
    },

    /** @function 删除按钮组配置 */
    deleteBtnConf(item, index) {
      console.log('删除: ', item, index);
      if (this.btnConfigs.length <= 1) {
        return;
      }
      this.btnConfigs.splice(index, 1);
    },

    /** @function 校验按钮名称是否重复 */
    checkIsRepeat(btnConf, index, e) {
      this.$nextTick(() => {
        console.log('校验按钮名称是否重复=> ', btnConf, index, e);
        const val = e.target.value?.trim();
        if (isEmptyObj(val)) {
          return;
        }

        const arr = _.cloneDeep(this.btnConfigs);

        arr.splice(index, 1);

        const repeatArrLength = arr.filter(it => it.buttonName === val).length || 0;

        if (repeatArrLength > 0) {
          this.$message.error('按钮名称不能重复');
          const newConf = _.cloneDeep(btnConf);
          newConf.buttonName = '';
          this.btnConfigs.splice(index, 1, newConf);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add_tab {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0;
}
.title {
  font-size: 16px;
  line-height: 2.5;
  background: #eee;
  padding-left: 20px;
}
.empty_data {
  text-align: center;
  min-height: 100px;
  line-height: 100px;
}

.mini-input {
  width: 100px;
}

.footer {
  margin-top: 20px;
  text-align: center;
}

.add_question_btn {
  display: block;
  background: none;
  text-align: center;
  border: 1px dashed #d0d0d0;
  width: 100%;
  padding: 10px;
  color: #666;
  margin-top: 20px;
  cursor: pointer;
}

.table {
  width: 100%;
  border-collapse: collapse;
  thead {
    color: #999;
    border-bottom: 1px solid #eee;

    th {
      width: 200px;
    }
  }

  tr {
    border-bottom: 1px solid #eee;
  }

  th,
  td {
    text-align: center;
    padding: 10px 20px;
  }
}
.question_wrap {
  display: inline-block;
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tip_wrap {
  width: 400px;
}
.el-tooltip__popper {
  max-width: 60% !important; //宽度可根据自己需要进行设置
}
.is_top {
  cursor: pointer;
  font-size: 12px;
}

.tab_item {
  color: #303133;
}

.tab_item[active='true'] {
  color: #409eff;
}

.mt22 {
  margin-top: 22px;
}

.add-question-btn {
  width: 100%;
  border-style: dashed;
}

.question-body {
  margin: -30px 0;
}

.edit_wrap {
  display: flex;

  .ndd-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #20a0ff;
    }

    .ndd-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 148px;
      height: 148px;
      text-align: center;
    }

    .ndd-uploader-img {
      width: 146px;
      height: 146px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;

      .ndd-uploader-delete {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        font-size: 12px;
        line-height: 30px;
        background-color: red;
        color: white;
        border-radius: 4px;
        cursor: pointer;
      }

      &:hover {
        .ndd-uploader-delete {
          display: block;
        }
      }

      img {
        width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }

  .el-upload__tip {
    margin-left: 8px;
    align-self: center;
  }
}

.btn-groups {
  margin-top: 35px;

  &:first-child {
    margin-top: 0;
  }
}
</style>
