<template>
  <div class="question-list">
    <h2 v-if="!isComponent" class="title">知识管理</h2>
    <bk-list
      ref="list"
      :search-config="searchConfig"
      :button-config="buttonConfig"
      :table-config="tableConfig"
    />
  </div>
</template>
<script>
import { formatDate } from '../../utils/util';

export default {
  props: {
    status: {
      type: Number,
      default: null,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    isComponent: {
      type: Boolean,
      default: false,
    },
    autoHeight: {
      type: Number || Boolean,
      default: false,
    },
  },
  data() {
    // eslint-disable-next-line no-underscore-dangle
    const _this = this;
    return {
      searchConfig: {
        data: [
          {
            data: [
              {
                name: 'channelCode',
                label: '业务来源',
                type: 'select',
                grid: 1,
                // multiple: true,
                props: {
                  // multiple: true,
                  // emitPath: false,
                  // checkStrictly: true,
                  value: 'channelCode',
                  label: 'channelName',
                },
                http: {
                  url: '/api/im-web/knowledge/problemOrginList',
                },
                // loadModel: 'hasFlag',
                // subsetDepend: {
                //     name: 'children',
                //     value: [],
                // },
                // level: 3,
              },
              {
                name: 'titles',
                label: '问题内容/编号',
                type: 'input',
                grid: 3,
              },
              {
                name: 'classificaCode',
                label: '问题分类',
                type: 'select',
                grid: 3,
                extend: {
                  clearable: true,
                },
                props: { value: 'classificaCode', label: 'classificaName' },
                http: {
                  url: '/api/im-web/knowledge/classificaPageList',
                },
              },
              {
                name: 'label',
                label: '问题标签',
                type: 'select',
                grid: 3,
                extend: {
                  clearable: true,
                },
                options: [
                  {
                    value: '1',
                    label: '个人投保',
                  },
                  {
                    value: '2',
                    label: '企业投保',
                  },
                ],
              },
            ],
          },
        ],
      },
      buttonConfig: {},
      tableConfig: {
        autoHeight: _this.autoHeight,
        selection: _this.isComponent,
        extend: {
          'reserve-selection': true, // 用于保留跨页选择的数据
          'row-key': 'titleCode',
        },
        http: {
          url: '/api/im-web/knowledge/callSearchProblemList',
          responseListProps: {
            results: 'list',
            totalCount: 'totalNumber',
          },
          onBefore(params) {
            if (_this.ids) {
              return {
                ...params,
                ids: _this.ids.join(','),
                judgeStatus: _this.status,
              };
            }
            return params;
          },
        },
        data: [
          {
            name: 'titleCode',
            label: '问题编号',
          },
          {
            name: 'title',
            label: '问题标题',
          },
          {
            name: 'channelName',
            label: '业务来源',
          },
          {
            name: 'classificaName',
            label: '分类',
          },
          {
            name: 'labelName',
            label: '标签',
          },
          {
            name: 'gmtCreate',
            // name: 'et',
            label: '创建时间',
            render(h, params) {
              const { gmtCreate } = params.data.attrs.row;
              return <span>{formatDate(new Date(gmtCreate), 'yyyy-mm-dd HH:ii:ss')}</span>;
            },
          },
          {
            name: 'switchStateName',
            label: '状态',
          },
          {
            name: 'operation',
            label: '操作',
            type: 'operation',
            visible: !this.isComponent,
            data: [
              {
                name: '编辑',
                label: '编辑',
                scene: 'skip',
                visible: () => this.checkPermission('KFXT_ZSK_ZSKBJ'),
                http: {
                  url: '/question/detail',
                  targetKeys: ['id'],
                  toString: true,
                },
              },
              {
                name: '查看',
                label: '查看',
                scene: 'skip',
                http: {
                  url: '/question/show',
                  targetKeys: ['id'],
                  toString: true,
                },
              },
              {
                name: '删除',
                label: '删除',
                scene: 'normal',
                visible: () => this.checkPermission('KFXT_ZSK_ZSKSC'),
                http: {
                  url: '/api/im-web/knowledge/deleteProblem',
                  targetKeys: ['id'],
                  toString: true,
                  confirm: '操作后无法恢复，确认删除该问题吗',
                },
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.buttonConfig = {
        data: [
          {
            name: 'button1',
            label: '新增',
            selection: false,
            scene: 'skip',
            visible: () => !this.isComponent && this.checkPermission('KFXT_ZSK_ZSKBJ'),
            http: {
              url: '/question/detail',
            },
          },
        ],
      };
    }, 500);
  },
  methods: {
    checkPermission(code) {
      return this.$store.state.permissionData.indexOf(code) !== -1;
    },
    getData(fn) {
      fn(this.$refs.list.tableSelection);
    },
    clearSelection() {
      // console.log();
      this.$refs.list.clearSelection();
    },
  },
};
</script>
